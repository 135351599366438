<template>

  <div class="main">
    <div class="mg">
      <div class="top"></div>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/product-center' }"
          >产品中心</el-breadcrumb-item
        >
      </el-breadcrumb>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div class="sidenav">
      <el-row class="tac">
        <el-col>
          <el-menu
            :default-active="$store.state.activeIndex"
            class="el-menu-vertical-demo"
            active-text-color="#FFA500"
            :router="true"
            ref="menu"
          >
            <el-menu-item
              :index="p.id"
              :route="`/product-center/${p.nick}`"
              v-for="p in $store.state.productList"
              :key="p.id"
              ref="mi"
            >
              <span slot="title">{{ p.title }}</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>

</template>

<script>
export default {
  name: "ProductCenter",
  components: {
    // ProductList,
  },
  data() {
    return {
      cate: 'JoggingShoes'
    };
  },
  methods: {},
  watch: {
    // $route(to, from) {
    //   // 对路由变化作出响应...

    // }
  },
  directives: {
    // hClick(el, binding) {
    //   if (binding.value === 0) {
    //     console.log(12122);
    //     // console.log(el);
    //     setTimeout(() => {
    //       el.click();
    //     });
    //   }
    // },
    // hClick: {
    //   bind(el, binding) {
    //     // console.log(el, binding);
    //     // if (binding.value === 0) {
    //       console.log(1);
    //       // el.click();
    //     // }
    //   },
    //   inserted(el, binding) {
    //     // if (binding.value === 0) {
    //       console.log(2);
    //       // console.log(el);
    //       // setTimeout(() => {
    //       //   el.click();
    //       // });
    //     // }
    //   },
    //   update(el, binding) {
    //     // if (binding.value === 0) {
    //       console.log(3);
    //       // console.log(el);
    //       // setTimeout(() => {
    //       //   el.click();
    //       // });
    //     // }
    //   },
    // },
  },
  // mounted() {
  //   console.log("ProductCenter mounted");
  // },
  // activated() {
  //   console.log("ProductCenter activated");
  //   this.$refs.mi[0].$el.click();
  // },
};
</script>

<style scoped>
a {
  color: #000;
}

.main .mg {
  background-color: #fff;
}
.top {
  width: 100%;
  height: 300px;
  background: url(../../assets/images/product-head.jpg) center no-repeat;
  background-size: cover;
}
.sidenav {
  position: absolute;
  left: 50%;
  top: 430px;
  margin-left: -600px;
  transform: translateX(-100%);
  /* width: 150px; */
  height: 300px;
  padding: 15px 0 15px 0;
  background-color: #fff;
  z-index: 999999;
}

.el-menu {
  border: none;
}

.el-breadcrumb {
  height: 40px;
  line-height: 40px;
}
.el-menu-item {
  height: 50px;
  line-height: 50px;
}
</style>